import React from "react"

type Props = {
  className?: string
  src: string
  poster: string
  type?: string
}

const Video: React.FC<Props> = ({ className = "", src, poster, type }) => {
  return (
    <video
      muted
      autoPlay
      loop
      playsInline
      className={className}
      poster={poster}
    >
      <source src={src} type={type || "video/mp4"} />
    </video>
  )
}

export default Video

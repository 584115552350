import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArrowDown from "../images/svg/arrow-down.svg"
import Accordion from "../components/accordion"
import Video from "../components/video"
import Video1 from "../videos/about-header/ore-ore-ore-1-c.mp4"
import Video2 from "../videos/about-header/ore-ore-ore-2-c.mp4"
import Video3 from "../videos/about-header/ore-ore-ore-3-c.mp4"
import Video4 from "../videos/about-header/ore-ore-ore-4-c.mp4"
import VideoPoster1 from "../videos/about-header/ore-ore-ore-1-c0001.png"
import VideoPoster2 from "../videos/about-header/ore-ore-ore-2-c0001.png"
import VideoPoster3 from "../videos/about-header/ore-ore-ore-3-c0001.png"
import VideoPoster4 from "../videos/about-header/ore-ore-ore-4-c0001.png"
import BurnerPhone from "../videos/burner-phone/burner-phone.mp4"
import BurnerPhonePoster from "../videos/burner-phone/burner-phone.png"

const AboutPage: React.FC = () => {
  return (
    <Layout bgColor="black">
      <SEO title="About OREMOB" />

      <div className="overflow-hidden">
        <section className="relative flex min-h-screen flex-1 flex-col justify-between pb-[67px] lg:pb-[38px]">
          <div className="absolute top-0 right-0 left-0 bottom-[67px] -z-1 grid w-full grid-cols-1 items-center gap-5 md:grid-cols-2 lg:bottom-[38px] lg:grid-cols-4">
            <div className="bg-contain bg-center bg-no-repeat opacity-60">
              <Video src={Video1} poster={VideoPoster1} />
            </div>
            <div className="hidden bg-contain bg-center bg-no-repeat opacity-60 md:block">
              <Video src={Video2} poster={VideoPoster2} />
            </div>
            <div className="hidden bg-contain bg-center bg-no-repeat opacity-60 md:block">
              <Video src={Video3} poster={VideoPoster3} />
            </div>
            <div className="hidden bg-contain bg-center bg-no-repeat opacity-60 md:block">
              <Video src={Video4} poster={VideoPoster4} />
            </div>
          </div>

          <div className="invisible h-1" />
          <div className="container flex flex-col items-center space-y-8 text-white">
            <div className="m-0 flex flex-col justify-center font-gravity-compressed">
              <span className="text-center text-[120px] leading-none lg:text-[180px]">
                OREMOB
              </span>
              <span className="text-center text-[60px] leading-none lg:text-[120px]">
                by ORE ORE ORE
              </span>
            </div>
            <div className="max-w-md text-center text-[15px] font-light leading-[23.4px] lg:max-w-screen-sm lg:text-[19px]">
              It&apos;s the natural expansion of a project that&apos;s been
              unfolding since 2021, and the OREMOB has started. Drops from the
              ORE 30, ORE Demon, 100 and 111 Drops paved the way for this
              community to become the most talked about art and digital identity
              project on Cardano.
            </div>
          </div>

          <div className="mb-5 flex flex-row items-center justify-center space-x-2 text-center text-[25px] uppercase leading-[76.5%]">
            <ArrowDown className="h-[25px]" />
            <span className="leading-none">Scroll Down</span>
          </div>
        </section>

        <section className="container mt-[30px] flex flex-1 flex-col space-y-32">
          <Accordion
            expandFirst
            className="self-center lg:max-w-screen-lg lg:px-8"
            texts={[
              {
                title: "ORE’S PROJECT",
                text: (
                  <p>
                    ORE’S project is a collection of unique sets of characters
                    forged and mined from the visions of the artist ORE ORE ORE.
                    <br />
                    <br />
                    Acclaimed for building a distinctive visual identity, the
                    Berlin based artist creates a unique narrative in his
                    pieces, drawing from 90s and Y2K nostalgia while at the same
                    time celebrating modern underground-, street- and pop
                    culture. Striking between reality and nostalgia, youth and
                    delinquency, money and art, scars and resolve.
                  </p>
                ),
              },
              {
                title: "POLICY IDs",
                text: (
                  <>
                    <p>
                      ORE 30 DROP:
                      <br />
                      79373382896f6277fe42e03eee896d9b54a8ab22373173695d499c83
                      <br />
                      <br />
                    </p>
                    <p>
                      Joel Madden Airdrop:
                      <br />
                      e3dceacdd9c7dcdcf3bc70de05e8ca021272cc9ae7080ebd2997139b
                      <br />
                      <br />
                    </p>
                    <p>
                      ORE 100 DROP:
                      <br />
                      c1600ab221f2b0d1047863d1fa5eaf294e1ea0765c582c44a6e99a03
                      <br />
                      <br />
                    </p>
                    <p>
                      DEMON DROP:
                      <br />
                      2c278e14754e066c0ee63bab30b3d283b91f12ab749e0bb2ad86f3be
                      <br />
                      <br />
                    </p>
                    <p>
                      ORE 111 DROP:
                      <br />
                      9eeb7285a120558aa4fb686b35d17b8d204f66da51043e602f468f81
                      <br />
                      <br />
                    </p>
                    <p>
                      BURNER PHONES EDITION I:
                      <br />
                      d6a4ae3755ad378e3d95dd02c1a02b36f89143c42c54997eb491bb26
                      <br />
                      <br />
                    </p>
                    <p>
                      BURNER PHONES MOB (NPC AIRDROP):
                      <br />
                      c8d993fb66cb699f4e7bd4d958fe8e1ba344fb4ca9c3ad6efd8ffbfa
                      <br />
                      <br />
                    </p>
                    <p>
                      BURNER PHONES EDITION II:
                      <br />
                      73adbeec8b48e828837063032acc60af20ba535b3f6f0a39d938ab3b
                      <br />
                      <br />
                    </p>
                    <p>
                      ORE CHARM RING V1:
                      <br />
                      2588988c828eba66f40613ddeabb394349598468c54e2225dc1c7d06
                      <br />
                      <br />
                    </p>
                    <p>
                      BURNER PHONE UPGRADE 1.2:
                      <br />
                      c754b508ffbc453b1264680aa63efd9f226e579f0d07f00ae9a48ab3
                      <br />
                      <br />
                    </p>
                    <p>
                      ORE Burner Phone MOB Canary Violet:
                      <br />
                      ccdb41d4ec6638273277d4ef77f8becc833dc7797a44735308bfe30f
                      <br />
                      <br />
                    </p>
                    <p>
                      ORE CHARM RING V2:
                      <br />
                      a8d2dbfb15a6da229e0e6f51a7375c9330cfb10b84d27cfc01202c49
                      <br />
                      <br />
                    </p>
                    <p>
                      OREMOB 10K AVATAR DROP:
                      <br />
                      062b1da3d344c1e6208ef908b2d308201e7ff6bcfddf0f606249817f
                      <br />
                      <br />
                    </p>
                  </>
                ),
              },
              {
                title: "WHY CARDANO?",
                text: (
                  <p>
                    The NFT market has quickly evolved into a real perspective
                    for artists to sustain and develop their unique perspectives
                    and minds. It is without a doubt part of an evolution
                    towards something new and existing.
                    <br />
                    <br />
                    To ORE ORE ORE and the team behind this NFT drop it is of
                    the highest priority that the steps towards this evolution
                    are being taken with a progressive and environmentally
                    conscious mind.
                    <br />
                    <br />
                    With its proof-of-stake protocol, the Cardano blockchain
                    stands as one of the most environmentally friendly
                    blockchains. Cardano’s annual energy consumption are less
                    than 0.01 % compared to Ethereum and even less than 0.001 %
                    compared to Bitcoin.
                  </p>
                ),
              },
            ]}
          />
        </section>

        <div
          id="burnerphone"
          className="mb-20 flex flex-col space-y-8 lg:space-y-16"
        >
          <div className="mt-24 break-words text-center font-gravity-compressed text-[60px] uppercase leading-[1em] lg:text-[180px]">
            THE BURNER PHONE MOB
          </div>
          <div className="mx-auto flex flex-wrap items-center justify-center lg:max-w-[1200px]">
            <div className="w-full lg:w-1/2">
              <Video
                className="mx-auto aspect-[600/750]"
                src={BurnerPhone}
                poster={BurnerPhonePoster}
              />
            </div>
            <div className="w-full p-4 lg:w-1/2 ">
              Collectors of ORE Artworks have become loyal followers of the
              OREMOB and its ventures into the metaverse. As a reward and
              privilege, they are entrusted with specific tasks for the MOB.
              <br />
              <br />
              With the introduction of the OREMOB, the connection with the
              artist will be deepened further, and a massive call to action will
              be issued to cowrite the MOB&apos;s storyline from inception to
              global dominance.
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-16">
          <div
            id="faq"
            className="text-center font-gravity-compressed text-[60px] uppercase leading-[1em] lg:text-[180px] "
          >
            FAQ
          </div>

          <Accordion
            expandFirst
            className="self-center px-4 lg:max-w-screen-lg lg:px-8"
            texts={[
              {
                title:
                  "WEN MINT / HOW CAN I GET MY HANDS ON THE ORE OGs and OREMOB NFT's?",
                text: (
                  <p>
                    The ORE OGs have so far sold and minted in collections of
                    11-111. Depending on the output of the Artist{" "}
                    <a
                      href="https://instagram.com/ore_ore_ore"
                      target="_blank"
                      className="underline"
                      rel="noreferrer noopener"
                    >
                      ORE ORE ORE
                    </a>{" "}
                    and the specific makeup of the drop. It might feel like a
                    lot to begin with, yet once you talk to some of the
                    collectors you quickly find yourself immersed in an
                    incredibly layered art project, with deep lore and a firm
                    community. This “MOB” is unwavering in their belief in the
                    value of the Cardano NFT artworks by ORE ORE ORE.
                    <br />
                    <br />
                    The OREMOB 10k Avatar drop is happening in Autumn 2022. This
                    will enlarge the community further for the purpose of
                    growing its reach. With ambitious plans to become the most
                    active and renowned community in the Cardano ecosystem. With
                    this in mind, checking in on the regular works well to keep
                    you in the loop. Depending on the speed of the sale you may
                    have ample time to mint an OREMOB Avatar/PFP during the
                    public sale.
                    <br />
                    <br />
                    Most whitelist or pre-sale options come from engaging with
                    the community on Discord or Twitter, or holding earlier ORE
                    Project NFT&apos;s. The community is called the OREMOB and
                    their Emojis are ⭕️✨.
                    <br />
                    <br />
                    <span className="underline">Chronologically:</span>
                    <br />
                    <br />
                    ORE OGs - You can buy them on secondary markets like{" "}
                    <a
                      href="https://www.jpg.store/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer noopener"
                    >
                      Jpg.Store
                    </a>{" "}
                    - Simply enter “ORE ORE ORE” and you will see the different
                    drops pop up by collection name. If you are not part of the
                    discord you most def’ miss out on the OREMOB benefits, which
                    you can work towards as you climb the hierarchy. Many low
                    hanging fruits circle around actions initiated by our MOB
                    community leaders. You will spot them very quickly, via
                    server roles & being the most active on our discord server.
                    It might feel a lot in the beginning but once you talk to
                    some of the collectors you quickly find yourself immersed in
                    a great, layered art project with deep lore, and a firm
                    community. This “MOB” is unwavering in their belief in the
                    value of the Cardano NFT artworks by ORE ORE ORE.
                  </p>
                ),
              },
              {
                title:
                  "WHAT IS THE OREMOB NFT DISTRIBUTION FOR THE DIFFERENT STAGES?",
                text: (
                  <p>
                    OREMOB distribution determines the different allocations for
                    each stage of the mint, as well as attributions to partners
                    and the team.
                    <br />
                    <br />
                    WHITE LIST + OG + BURNER PHONE HOLDER SALE
                    <br />
                    3500
                    <br />
                    <br />
                    PUBLIC SALE
                    <br />
                    6500
                    <br />
                    <br />
                    OREMOB HQ & PARTNERS
                    <br />
                    The Team, Artists, F&F & Community, allocation is needed to
                    onboard further key figures into this space over time. 250
                    included from the WL.
                    <br />
                    <br />
                    We built this mechanic with our early supporters in mind,
                    our team&apos;s immense work, and giving those who just
                    discovered OREMOB a chance to be part of the next generation
                    CNFT project.
                  </p>
                ),
              },
              {
                title:
                  "HOW CAN WE PARTICIPATE ON TWITTER AND DISCORD AND GAIN FROM THIS?",
                text: (
                  <p>
                    There are systems and moderators integrated and in place to
                    assure that active members will be incentivised. We will not
                    only have bots establish the value you give to these chats
                    but also your true self and will watch carefully how you
                    interact as a person. We do not encourage aggressive
                    behaviour, nor the promotion or shaming of other projects to
                    upgrade our own MOB. Web3 is not meant to have a toxic
                    environment like many social media communities exhibit.
                    Knowing what it can feel like to be on the sideline makes us
                    not wish to be bullies but rather the opposite. There are
                    already special channels for very dedicated OREMOB members.
                    Examples of MOB specific channels:
                    <br />
                    Burner Phone MOB: Exclusive channel for holders of our
                    highly sought after (and extremely limited) BP pieces.
                    Vetted and available only to holders of OG ORE’s. Weekly
                    video calls with ORE & the team, airdrops + much more.
                    <br />
                    <br />
                    S-Class: For those who hold OG pieces or that have spent
                    significant time among the broader MOB.
                    <br />
                    <br />
                    Raid chat: A chat dedicated to our infamous Twitter Raiders.
                    The MOB has a reputation for showing up and representing in
                    an impressive fashion.
                  </p>
                ),
              },
              {
                title: "HOW CAN I CONTACT THE TEAM?",
                text: (
                  <p>
                    There are support ticket options in place and you can
                    currently access them by joining our discord and hitting us
                    up in the chat. Due to the commitment to the project and
                    collectors we stay away from DMs as this would at this
                    current state slow down the development of benefits for the
                    community and art itself, and leaves some risk with
                    scammers.
                    <br />
                    <br />
                    We have 20+ people coming in and out of the team, and
                    we&apos;ve onboarded parts of the community. Since the first
                    drops in 2021, Community Managers like @Genki and
                    @Mandragoran have been an integral part of our voice. We
                    keep iterating. It&apos;s one step at a time, but we&apos;re
                    ambitious. If you like to get involved feel free to contact
                    our Community Managers.
                    <br />
                    <br />
                    Follow the Twitter accounts of the founders: Artist{" "}
                    <a
                      href="https://twitter.com/ore_times_3"
                      target="_blank"
                      className="underline"
                      rel="noreferrer noopener"
                    >
                      @ore_times_3
                    </a>
                    ,{" "}
                    <a
                      href="https://twitter.com/rowweber"
                      target="_blank"
                      className="underline"
                      rel="noreferrer noopener"
                    >
                      @rowweber
                    </a>
                    , and Community Managers{" "}
                    <a
                      href="https://twitter.com/Mandragoran_ORE"
                      target="_blank"
                      className="underline"
                      rel="noreferrer noopener"
                    >
                      @Mandra
                    </a>{" "}
                    &{" "}
                    <a
                      href="https://twitter.com/Mandragoran_ORE"
                      target="_blank"
                      className="underline"
                      rel="noreferrer noopener"
                    >
                      @GENKI
                    </a>
                  </p>
                ),
              },
              {
                title:
                  "AM I A TRUE OWNER OF THE CARD OR NFT AND ARE THEY UNIQUE?",
                text: (
                  <p>
                    All ORE ORE ORE works are unique. Whether it is the OGs or
                    the OREMOB PFPs.
                    <br />
                    <br />
                    <strong>OG ORE:</strong>
                    <br />
                    <br />
                    Non Fungible Token format is the best way to ensure
                    authenticated + verifiable ownership of an artwork/asset.
                    Owning the token does not entail having rights over the
                    copyright of the ORE OGs though. You own the NFT that is a
                    one of one. So you can be sure that there is no other copy
                    of such kind. At the artist&apos;s discretion, possible
                    collaborations with OG artwork can be discussed.
                    <br />
                    <br />
                    <strong>OREMOB:</strong>
                    <br />
                    <br />
                    License to the Artwork
                    <br />
                    <br />
                    By transferring the NFT to the Buyer, OREMOB irrevocably
                    grants to the Buyer, limited in time to the period during
                    which he is Owner of the NFT and subject to the condition
                    precedent of payment of the Purchase Price pursuant to
                    section 2.5, the worldwide, exclusive, not-sublicensable and
                    not-transferable right to use, copy, display, print and make
                    publicly available to the Artwork in connection with the NFT
                    solely Further details on the license are{" "}
                    <Link className="underline" to="/legal-notice/">
                      here
                    </Link>
                    .
                  </p>
                ),
              },

              {
                title: "WHICH WALLETS ARE SUPPORTED?",
                text: (
                  <p>
                    Wallets that support Shelley addresses like Etrnl, Nami
                    Wallet, Daedalus,Typhon, Yoroi, Adalite, amongst others.
                    NEVER send from an exchange.
                  </p>
                ),
              },
              {
                title: "WHEN WILL I RECEIVE MY ARTWORK?",
                text: (
                  <p>
                    Right after payment confirmation, our mint process will be
                    executed automatically and the transaction will be submitted
                    to the blockchain. You will receive a JPG. and sometimes
                    .GIF, and both will also contain a high resolution .MP4
                    file.
                  </p>
                ),
              },
              {
                title: "WHEN AM I ELIGIBLE FOR A PRE-SALE?",
                text: (
                  <p>
                    ORE OGs bought in prior drops allow you multiple (2-5)
                    pre-sale (WL), reserved mint options for the time limited
                    pre-sale of the OREMOB 10k drop. We also have a WL tracker
                    where you can check on your WL allocation for the time
                    being. Further WL are being given away via raffles and
                    spontaneous activations like raids. You can approach the ORE
                    MOB MOD’s on Discord to get involved.
                  </p>
                ),
              },
              {
                title:
                  "HOW DOES THE MINTING PROCESS OF THE BURNER PHONES WORK AND HOW DO I REMAIN PART OF THE BURNER PHONE MOB?",
                text: (
                  <p>
                    Even though we love the anonymous world, we want to ensure
                    there is fair play, so we&apos;ll ask our MOB to perform a
                    basic KYC. Through the KYC we are linking the burner phone
                    directly to the original wallet address (Therefore it cannot
                    be transferred to another wallet without losing
                    functionality). In instances where safety or security
                    concerns exist surrounding basic KYC, please discuss your
                    circumstances with the team.
                    <br />
                    <br />
                    Burner Phones were available for registration on the 28th of
                    January 2022 12:30 pm EST. OGs bought in prior drops, except
                    airdrops, gave one reserved mint option. You had 48 hours to
                    mint yours. Phones that were not picked up were burned and
                    not made available after that period until the next ORE
                    Drop. The perks for Burner Phone OGs are significant during
                    the OREMOB 10k drop, yet if you are an OG now with no phone
                    you will miss out on those.
                    <br />
                    <br />
                    The registration closed on Sunday the 30th 12:30 PM EST and
                    the sending out of the magic links happened ca. 4:30 PM via
                    email in phases. There was a 48 hour countdown for minting
                    them. If you failed to mint your phone it was burned.
                    <br />
                    <br />
                    Further Burner Phone Initiation windows to follow in late
                    2022.
                    <br />
                    <br />
                    Burner Phone NFT&apos;s require an ORE OG Card and a Burner
                    Phone in one wallet. You lose its activity and eligibility
                    to remain part of the Burner Phone MOB if you sell your OGs
                    and leave the BP in the wallet alone.
                  </p>
                ),
              },
              {
                title: "ARE YOU IN TWITTER SPACES OR DISCORD VOICE CHATS?",
                text: (
                  <p>
                    We hold our OREMOB SAfE HOUsE Twitter space every Monday.
                    The exact time is always updated on the discord chat and
                    twitter. We also attend several spaces each week with
                    different camps. There is a weekly Burner Phone MOB Chat
                    with up to 50 people from the community that can be attended
                    if you own a Burner Phone.
                  </p>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
